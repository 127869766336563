.about{
    font-size: 14pt;
    color: black;
    font-family: Montserrat;
    line-height: 1.65;
}

.contact{
    font-weight: bolder;
}

.darkContact{
    font-weight: bolder;
    color:white;
}

.darkAbout{
    font-size: 14pt;
    color:white;
    font-family: Montserrat;
    line-height: 1.65;
}
.title{
    font-size: 18pt;
    font-weight: bolder;
    font-family: Montserrat; 
}

.darkTitle{
    font-size: 18pt;
    font-weight: bolder;
    font-family: Montserrat;
    color:white;
}
