@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align:left;
  overflow-x: none;
}
.section{
  padding-bottom: 50px;
}

.back{
  background:rgb(245, 245, 245);
  -webkit-animation: lightMode-switch 1000ms backwards;
          animation: lightMode-switch 1000ms backwards;

}
.darkBack{
  background-color:rgb(23,28,40);
  -webkit-animation: darkMode-switch 1000ms backwards;
          animation: darkMode-switch 1000ms backwards;
}

.bar{
  text-align: center;
  margin-bottom: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes darkMode-switch {
  from {
    background:rgb(245, 245, 245);
  }
  to {
    background-color:rgb(23,28,40);
  }
}

@keyframes darkMode-switch {
  from {
    background:rgb(245, 245, 245);
  }
  to {
    background-color:rgb(23,28,40);
  }
}


@-webkit-keyframes lightMode-switch {
  from {
    background-color:rgb(23,28,40);

  }
  to {
    background:rgb(245, 245, 245);
  }
}


@keyframes lightMode-switch {
  from {
    background-color:rgb(23,28,40);

  }
  to {
    background:rgb(245, 245, 245);
  }
}


.modeButton{
    position: relative;
    width:8vw;
    height: 8vw;
    margin-top:10px;
}

.sunButton{
    position: relative;
    width:8vw;
    height: 8vw;
    margin-top:10px;
    -webkit-animation: rotation 20s infinite linear;
            animation: rotation 20s infinite linear;
}

.name{
    margin-top: 1%;
    color:black;
    letter-spacing: 1px;
    font-weight:bold;
    font-size: 24pt;
    font-family: Montserrat; 
}

.darkName{
    margin-top: 1%;
    color:white;
    letter-spacing: 1px;
    font-weight:bold;
    font-size: 24pt;
    font-family: Montserrat; 
}

.menu{
    color:blue;
    font-size: large;
    margin-bottom: 1%;
    justify-content: center;
}

.darkMenu{
    color:white;
    font-size: large;
    margin-bottom: 1%;
    justify-content: center;

}

.menuInside{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scroll{
    padding:4px;
    color:blue;
}

.darkScroll{
    padding:4px;
    color:white;
}

.emojiContainer{
    padding:0px;
}


@-webkit-keyframes rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}


@keyframes rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}
.about{
    font-size: 14pt;
    color: black;
    font-family: Montserrat;
    line-height: 1.65;
}

.contact{
    font-weight: bolder;
}

.darkContact{
    font-weight: bolder;
    color:white;
}

.darkAbout{
    font-size: 14pt;
    color:white;
    font-family: Montserrat;
    line-height: 1.65;
}
.title{
    font-size: 18pt;
    font-weight: bolder;
    font-family: Montserrat; 
}

.darkTitle{
    font-size: 18pt;
    font-weight: bolder;
    font-family: Montserrat;
    color:white;
}

.education{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.title{
    position:relative;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    font-size: 16pt;
    font-weight: bolder;
    color:blue;
    /*color:rgb(0,123,255);*/
    font-family: Montserrat;
    margin-bottom: 20px;
}
.darkTitle{
    position:relative;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    font-size: 16pt;
    font-weight: bolder;
    color:rgb(0, 100, 255);
    /*color:rgb(0,123,255);*/
    font-family: Montserrat;
    margin-bottom: 20px;
}

.description{
    position: relative;
    width: 100%;
    padding-left:2%;
    padding-right: 2%;
}

.educationRow{
    display:flex;
    flex-direction: row;
    width: 100%   ;
    margin-bottom:5px;
}

.schoolName{
    font-size:22pt;
    text-align: left;
    font-weight:bold;
    color: orange;
}

.date{
    font-size:16pt;
}

.darkDate{
    font-size:16pt;
    color:white;
}

.major{
    font-size:14pt;
}
.darkMajor{
    font-size:14pt;
    color:white;
}

.graduationDate{
    margin-left: 50px;
    font-size:24pt;
}
.darkGradDate{
    margin-left: 50px;
    font-size:24pt;
    color:white;
}

.logo{
    position: relative;
    width: 42pt;
    height: 32pt;
    border-radius: 25pt;
    margin-right:10px
}

.gradTitle{
    font-size:16pt;
    font-weight: bold;
    margin-right:5px;
}

.darkGradTitle{
    font-size:16pt;
    font-weight: bold;
    margin-right:5px;
    color:white;
}
.education{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.title{
    position:relative;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    font-size: 18pt;
    font-weight: bolder;
}
.description{
    position: relative;
    width: 100%;
    padding-left:2%;
    padding-right: 2%;
}

.section{
    padding-bottom: 50px;
  }

  .projectImg{
      height: 220px;
  }

  .treeImg{
    height: 220px;
    background:orange;
}
.education{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.title{
    font-size: 18pt;
    font-weight: bolder;
}
.description{
    position: relative;
    width: 100%;
    padding-left:2%;
    padding-right: 2%;
    color:white
}

.interestLogo{
    position: relative;
    width: 256px;
    background-color: white;
    border-radius: 30%;
    padding:2%;
    margin-right:10px;
    border-color: black;
}

.interestTitle{
    font-size:16pt;
    font-weight: bold;
    margin-top:10px;
    margin-bottom:10px;
}
.darkIntTitle{
    font-size:16pt;
    font-weight: bold;
    margin-top:10px;
    margin-bottom:10px;
    color:white
}

.involvementCont{
    margin-bottom: 50px;
}
