
.modeButton{
    position: relative;
    width:8vw;
    height: 8vw;
    margin-top:10px;
}

.sunButton{
    position: relative;
    width:8vw;
    height: 8vw;
    margin-top:10px;
    animation: rotation 20s infinite linear;
}

.name{
    margin-top: 1%;
    color:black;
    letter-spacing: 1px;
    font-weight:bold;
    font-size: 24pt;
    font-family: Montserrat; 
}

.darkName{
    margin-top: 1%;
    color:white;
    letter-spacing: 1px;
    font-weight:bold;
    font-size: 24pt;
    font-family: Montserrat; 
}

.menu{
    color:blue;
    font-size: large;
    margin-bottom: 1%;
    justify-content: center;
}

.darkMenu{
    color:white;
    font-size: large;
    margin-bottom: 1%;
    justify-content: center;

}

.menuInside{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scroll{
    padding:4px;
    color:blue;
}

.darkScroll{
    padding:4px;
    color:white;
}

.emojiContainer{
    padding:0px;
}


@keyframes rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}