.App {
  text-align:left;
  overflow-x: none;
}
.section{
  padding-bottom: 50px;
}

.back{
  background:rgb(245, 245, 245);
  animation: lightMode-switch 1000ms backwards;

}
.darkBack{
  background-color:rgb(23,28,40);
  animation: darkMode-switch 1000ms backwards;
}

.bar{
  text-align: center;
  margin-bottom: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes darkMode-switch {
  from {
    background:rgb(245, 245, 245);
  }
  to {
    background-color:rgb(23,28,40);
  }
}


@keyframes lightMode-switch {
  from {
    background-color:rgb(23,28,40);

  }
  to {
    background:rgb(245, 245, 245);
  }
}
