.education{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.title{
    position:relative;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    font-size: 16pt;
    font-weight: bolder;
    color:blue;
    /*color:rgb(0,123,255);*/
    font-family: Montserrat;
    margin-bottom: 20px;
}
.darkTitle{
    position:relative;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    font-size: 16pt;
    font-weight: bolder;
    color:rgb(0, 100, 255);
    /*color:rgb(0,123,255);*/
    font-family: Montserrat;
    margin-bottom: 20px;
}

.description{
    position: relative;
    width: 100%;
    padding-left:2%;
    padding-right: 2%;
}

.educationRow{
    display:flex;
    flex-direction: row;
    width: 100%   ;
    margin-bottom:5px;
}

.schoolName{
    font-size:22pt;
    text-align: left;
    font-weight:bold;
    color: orange;
}

.date{
    font-size:16pt;
}

.darkDate{
    font-size:16pt;
    color:white;
}

.major{
    font-size:14pt;
}
.darkMajor{
    font-size:14pt;
    color:white;
}

.graduationDate{
    margin-left: 50px;
    font-size:24pt;
}
.darkGradDate{
    margin-left: 50px;
    font-size:24pt;
    color:white;
}

.logo{
    position: relative;
    width: 42pt;
    height: 32pt;
    border-radius: 25pt;
    margin-right:10px
}

.gradTitle{
    font-size:16pt;
    font-weight: bold;
    margin-right:5px;
}

.darkGradTitle{
    font-size:16pt;
    font-weight: bold;
    margin-right:5px;
    color:white;
}